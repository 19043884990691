/* Colors from Figma Compound https://www.figma.com/file/X4XTH9iS2KGJ2wFKDqkyed/Compound?node-id=559%3A741 */
/* ******************** */
$dark-primary-content: var(--cpd-color-text-primary);
$dark-secondary-content: var(--cpd-color-text-secondary);
$dark-tertiary-content: var(--cpd-color-gray-800);
$dark-quaternary-content: var(--cpd-color-gray-600);
$dark-quinary-content: var(--cpd-color-gray-400);

$dark-system: var(--cpd-color-bg-subtle-primary);
$dark-system-transparent: #e1e6ec00;
$dark-background: var(--cpd-color-bg-canvas-default);
$dark-overlay-background: var(--cpd-color-alpha-gray-1300);

$dark-panels: var(--cpd-color-bg-subtle-secondary);
$dark-panel-actions: var(--cpd-color-alpha-gray-300);

$dark-separator: var(--cpd-color-gray-400);

/* ******************** */

/* RoomList */
/* ******************** */
$dark-roomlist-bg-color: rgba(38, 40, 45, 0.9);
$dark-roomsublist-skeleton-ui-bg: linear-gradient(180deg, $dark-background 0%, #ffffff00 100%);
$dark-roomtile-default-badge-bg-color: var(--cpd-color-icon-secondary);
/* ******************** */

/**
 * Creating a `semantic` color scale. This will not be needed with the new
 * visual language, but necessary during the transition period
 * This abstract the `green` away from where accent shades are used
 * Take: `background: rgba($accent, 0.1);`
 * would be transformed to: `background: $accent-300;`
 *
 * To use under very rare circumstances, always prefer the semantics defined
 * in https://compound.element.io/?path=/docs /tokens-semantic-colors--docs
 */
$dark-accent-100: var(--cpd-color-green-100);
$dark-accent-200: var(--cpd-color-green-200);
$dark-accent-300: var(--cpd-color-green-300);
$dark-accent-400: var(--cpd-color-green-400);
$dark-accent-500: var(--cpd-color-green-500);
$dark-accent-600: var(--cpd-color-green-600);
$dark-accent-700: var(--cpd-color-green-700);
$dark-accent-800: var(--cpd-color-green-800);
$dark-accent-900: var(--cpd-color-green-900);
$dark-accent-1000: var(--cpd-color-green-1000);
$dark-accent-1100: var(--cpd-color-green-1100);
$dark-accent-1200: var(--cpd-color-green-1200);
$dark-accent-1300: var(--cpd-color-green-1300);
$dark-accent-1400: var(--cpd-color-green-1400);

/* Reused Figma non-compound colors */
/* ******************** */
$dark-inverted-bg-color: var(--cpd-color-bg-action-primary-rest);
$dark-header-panel-bg-color: var(--cpd-color-bg-subtle-secondary);
$dark-header-panel-bg-hover: var(--cpd-color-gray-200);
/* ******************** */

/* Theme specific colors */
/* ******************** */
$dark-icon-button-color: var(--cpd-color-icon-tertiary);
/* ******************** */

/* Colors that aren't in Figma and are theme specific - we need to get rid of these */
/* ******************** */
$dark-header-panel-text-secondary-color: #c8c8cd;
$dark-room-highlight-color: #343a46;
$dark-text-secondary-color: #b9bec6;
/* ******************** */

/* Colors that aren't in Figma - we need to get rid of these */
/* ******************** */
$dark-dark-panel-bg-color: var(--cpd-color-bg-subtle-secondary);
$dark-muted-fg-color: $header-panel-text-primary-color;
$dark-light-fg-color: $dark-header-panel-text-secondary-color;
$dark-focus-bg-color: $dark-room-highlight-color;
$dark-info-plinth-bg-color: $dark-header-panel-bg-color;
$dark-event-selected-color: var(--cpd-color-bg-subtle-secondary);
$dark-topleftmenu-color: $dark-primary-content;
$dark-roomtopic-color: $dark-text-secondary-color;
$dark-spacePanel-bg-color: rgba(38, 39, 43, 0.82);
$dark-panel-gradient: rgba(34, 38, 46, 0), rgba(34, 38, 46, 1);
$dark-h3-color: $dark-primary-content;
$dark-event-highlight-bg-color: #25271f;
$dark-header-panel-text-primary-color: $dark-text-secondary-color;
/* ******************** */

/* Tooltip */
/* ******************** */
$dark-tooltip-timeline-bg-color: $dark-spacePanel-bg-color;
$dark-tooltip-timeline-fg-color: $dark-primary-content;
/* ******************** */

/* Widget */
/* ******************** */
$dark-widget-menu-bar-bg-color: $dark-header-panel-bg-color;
$dark-widget-body-bg-color: $dark-panel-actions;
/* ******************** */

/* Menu */
/* ******************** */
$dark-menu-border-color: #000000;
$dark-menu-bg-color: $dark-header-panel-bg-color;
$dark-menu-box-shadow-color: $dark-background;
$dark-menu-selected-color: $dark-room-highlight-color;
/* ******************** */

/* Settings */
/* ******************** */
$dark-settings-profile-button-bg-color: #e7e7e7;
$dark-settings-subsection-fg-color: $dark-text-secondary-color;
/* ******************** */

/* Room */
/* ******************** */
$dark-room-icon-unread-color: var(--cpd-color-icon-tertiary);
/* ******************** */

/* RoomHeader */
/* ******************** */
$dark-roomheader-addroom-bg-color: rgba(92, 100, 112, 0.3);
$dark-roomheader-addroom-fg-color: $dark-primary-content;
/* ******************** */

/* Rich-text-editor */
/* ******************** */
$dark-pill-bg-color: var(--cpd-color-bg-action-primary-rest);
$dark-pill-hover-bg-color: var(--cpd-color-bg-action-primary-hovered);
$dark-pill-press-bg-color: var(--cpd-color-bg-action-primary-pressed);
/* ******************** */

/* Inputs */
/* ******************** */
$dark-input-border-color: rgba(231, 231, 231, 0.2);
$dark-input-darker-bg-color: #181b21;
$dark-input-darker-fg-color: #61708b;
$dark-input-lighter-bg-color: #f2f5f8;
$dark-input-placeholder: var(--cpd-color-text-secondary);
/* ******************** */

/* Dialog */
/* ******************** */
$dark-dialog-title-fg-color: $dark-primary-content;
$dark-dialog-backdrop-color: #00000080;
$dark-dialog-close-fg-color: $dark-icon-button-color;
$dark-dialog-close-external-color: $dark-primary-content;
/* ******************** */

/* RoomList */
/* ******************** */
$dark-system: var(--cpd-color-bg-subtle-secondary);
$dark-roomsublist-skeleton-ui-bg: linear-gradient(180deg, #3e444c 0%, #3e444c00 100%);
$dark-roomtile-default-badge-bg-color: var(--cpd-color-icon-secondary);
/* ******************** */

/* Tabbed views */
/* ******************** */
$dark-tab-label-fg-color: $dark-secondary-content;
$dark-tab-label-active-fg-color: $dark-primary-content;
/* ******************** */

/* Buttons */
/* ******************** */
$dark-button-primary-fg-color: $dark-primary-content;
$dark-button-secondary-bg-color: transparent;
$dark-button-danger-fg-color: $dark-primary-content;
$dark-button-danger-disabled-fg-color: $dark-primary-content;
/* ******************** */

/* Toggle switch */
/* ******************** */
$dark-togglesw-off-color: $dark-room-highlight-color;
/* ******************** */

/* Authpage */
/* ******************** */
$dark-authpage-primary-color: $dark-primary-content;
/* ******************** */

/* Message action bar */
/* ******************** */
$dark-message-action-bar-bg-color: $dark-header-panel-bg-color;
$dark-message-action-bar-fg-color: $dark-header-panel-text-primary-color;
$dark-message-action-bar-border-color: #616b7f;
$dark-message-action-bar-hover-border-color: $dark-header-panel-text-primary-color;
/* ******************** */

/* Reaction row */
/* ******************** */
$dark-reaction-row-button-hover-border-color: $dark-header-panel-text-primary-color;
$dark-reaction-row-button-selected-bg-color: #1f6954;
/* ******************** */

/* Voice messages */
/* ******************** */
$dark-voice-record-stop-border-color: $dark-quaternary-content;
$dark-voice-record-icon-color: $dark-quaternary-content;
/* ******************** */

/* Bubble tiles */
/* ******************** */
$dark-eventbubble-self-bg: var(--cpd-color-green-300);
$dark-eventbubble-others-bg: var(--cpd-color-gray-300);
$dark-eventbubble-bg-hover: var(--cpd-color-bg-subtle-secondary);
/* ******************** */

/* Lightbox */
/* ******************** */
$dark-lightbox-background-bg-color: $dark-menu-border-color;
$dark-lightbox-background-bg-opacity: 0.85;
/* ******************** */

/* VoIP */
/* ******************** */
$dark-call-view-button-on-foreground: $dark-primary-content;
$dark-call-view-button-on-background: $dark-system;
$dark-call-view-button-off-foreground: $dark-system;
$dark-call-view-button-off-background: $dark-primary-content;
$dark-call-view-content-background: $dark-quinary-content;

$dark-video-feed-secondary-background: $dark-system;

$dark-call-system: $dark-system;
$dark-call-background: $dark-background;
$dark-call-primary-content: $dark-primary-content;
$dark-call-light-quaternary-content: #c1c6cd;
/* ******************** */

/* Location sharing */
/* ******************** */
$dark-location-live-color: #5c56f5;
$dark-location-live-secondary-color: #deddfd;
/* ******************** */

/* Location sharing */
/* ******************** */
.maplibregl-ctrl-attrib-button {
    color: $dark-background;
}
/* ******************** */

/* Voice Broadcast */
/* ******************** */
$dark-live-badge-color: #ffffff;
/* ******************** */

/* One-off colors */
/* ******************** */
$dark-progressbar-bg-color: var(--cpd-color-gray-200);
$dark-kbd-border-color: $strong-input-border-color;
$dark-visual-bell-bg-color: #800;
$dark-event-timestamp-color: var(--cpd-color-text-secondary);
$dark-composer-shadow-color: rgba(0, 0, 0, 0.28);
$dark-breadcrumb-placeholder-bg-color: #272c35;
$dark-theme-button-bg-color: #e3e8f0;
$dark-resend-button-divider-color: var(--cpd-color-gray-700);
$dark-inlinecode-border-color: $dark-quinary-content;
$dark-inlinecode-background-color: $dark-system;
$dark-codeblock-background-color: #2a3039;
$dark-scrollbar-thumb-color: rgba(255, 255, 255, 0.2);
$dark-selected-color: $dark-room-highlight-color;
/* ******************** */

/* blur amounts for left left panel (only for element theme) */
/* ******************** */
:root {
    --lp-background-blur: 45px;
}
/* ******************** */

body {
    color-scheme: dark;
}

/* Nasty hacks to apply a filter to arbitrary monochrome artwork to make it */
/* better match the theme.  Typically applied to dark grey 'off' buttons or */
/* light grey 'on' buttons. */
/* ******************** */
.mx_filterFlipColor {
    filter: invert(1);
}
/* ******************** */

/* markdown overrides */
/* ******************** */
.mx_EventTile_content .markdown-body {
    table {
        tr {
            background-color: $dark-menu-border-color;
        }

        tr:nth-child(2n) {
            background-color: #080808;
        }
    }
}
/* ******************** */

/* diff highlight colors */
/* ******************** */
.hljs-tag {
    color: inherit; /* Without this they'd be weirdly blue which doesn't match the theme */
}

.hljs-addition {
    background: #1a4b59;
}

.hljs-deletion {
    background: #53232a;
}
/* ******************** */

/* Splash Page Gradient */
.mx_SplashPage::before {
    background-image: radial-gradient(
            53.85% 66.75% at 87.55% 0%,
            hsla(0deg, 0%, 11%, 0.15) 0%,
            hsla(250deg, 100%, 88%, 0) 100%
        ),
        radial-gradient(41.93% 41.93% at 0% 0%, hsla(0deg, 0%, 38%, 0.28) 0%, hsla(250deg, 100%, 88%, 0) 100%),
        radial-gradient(100% 100% at 0% 0%, hsla(250deg, 100%, 88%, 0.3) 0%, hsla(0deg, 100%, 86%, 0) 100%),
        radial-gradient(106.35% 96.26% at 100% 0%, hsla(25deg, 100%, 88%, 0.4) 0%, hsla(167deg, 76%, 82%, 0) 100%) !important;
}
